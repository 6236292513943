body {
	font-family: Arial, Arial, Helvetica, sans-serif;
	font-size: 11px;
}

.gantt {
	/* width: 575px; */
	width: 100%;
	height: 500px;
	margin: 20px auto;
	border: 15px solid #ddd;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;  
}

.gantt:after {
   content: ".";
   visibility: hidden;
   display: block;
   height: 0;
   clear: both;
}

.fn-gantt {
	width: 100%;
}

.fn-gantt .fn-content {
	overflow: hidden;
	width: 100%;
}

/* left panel */

.fn-gantt .leftPanel {
	float: left;
	width: 225px;
	overflow: hidden;
	border-right: 1px solid #DDD;
}

.fn-gantt .row {
	float: left;
	height: 24px;
	line-height: 24px;
}
.fn-gantt .leftPanel .label {
	margin: 0 0 0 5px;
}

.fn-gantt .leftPanel .name, .fn-gantt .leftPanel .desc {
	float: left;
	height: 23px;
	margin: 0;
	border-bottom: 1px solid #DDD;
	background-color: #f6f6f6;
}
 
.fn-gantt .leftPanel .name {
	width: 110px;
}
 
.fn-gantt .leftPanel .desc {
	width: 115px;
}
 
.fn-gantt .spacer
{
	margin: -2px 0 1px 0;
	border-bottom: 1px solid #DDD;
	background-color: #f6f6f6;
}

/* right panel */

.fn-gantt .rightPanel {
	float: left;
	/* width: 349px; */
	width: 80%;
	overflow: hidden;
}

.fn-gantt .day {
	overflow: visible;
	width: 24px;
	line-height: 24px;
	text-align: center;
	border-left: 1px solid #DDD;
	border-bottom: 1px solid #DDD;
	margin: -1px 0 0 -1px;
} 

.fn-gantt .hollyday {
	background-color: #f9e5e2;
}

.fn-gantt .today {
	background-color: #e2ebff;
}

.fn-gantt .sa, .fn-gantt .sn, .fn-gantt .wd {
	height: 23px;
	margin: 0 0 0 -1px;
}

.fn-gantt .sa {
	background-color: #dfdfdf;
}

.fn-gantt .sn {
	background-color: #dedede;
}

.fn-gantt .wd {
	background-color: #f6f6f6;
}

.fn-gantt .rightPanel .month, .fn-gantt .rightPanel .year {
	float: left;
	overflow: hidden;
	border-left: 1px solid #DDD;
	border-bottom: 1px solid #DDD;
	height: 23px;
	margin: 0 0 0 -1px;
	background-color: #f6f6f6; 	
}

.fn-gantt-hint {
	border: 5px solid #edc332;
	background-color: #fff5d4;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px; 
    padding: 10px;
	position: absolute;
	display: none;
    z-index: 10001;
}

.fn-gantt .bar {
	border: none;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;   
    background-color: #89c5f8;
    height: 18px;
    margin: 4px 3px 3px 3px;
    position: relative;
    z-index: 10000;
}

.fn-gantt .ganttRed {
	background-color: #8F0000;
}

.fn-gantt .ganttGreen {
	background-color: #b3cf6f;
}

.fn-gantt .ganttOrange {
	background-color: #ff7e24;
}

/* bottom (navigation) */

.fn-gantt .bottom {
	clear: both;
	background-color: #f6f6f6;
	width: 100%;
}
.fn-gantt .navigate {
	text-align: center;
}
.fn-gantt .navigate .page-number {
	display: inline-block;
	font-size: 10px;
	height: 20px;
}

.fn-gantt .navigate .page-number span {
	color: #666666;
	margin: 5px;
	position: relative;
	top: 13px;
}

.fn-gantt .navigate a:link, .fn-gantt .navigate a:visited, .fn-gantt .navigate a:active {
	text-decoration: none;
}

.fn-gantt .nav-link {
	margin: 0px 0px 8px 0px;
	display: inline-block;
	width: 20px;
	height: 20px;
	font-size: 0px;
}

.fn-gantt .navigate .nav-page-back {
	background-image: url(img/buttons.png);
	background-position: 0 0;
}

.fn-gantt .navigate .nav-page-next {
	background-image: url(img/buttons.png);
	background-position: -50px 0;
	margin-right: 15px;
}

.fn-gantt .navigate .nav-page-back:hover {
	background-position: 0 -20px;
}

.fn-gantt .navigate .nav-page-next:hover {
	background-position: -50px -20px;
}

.fn-gantt .navigate .nav-begin {
	background-image: url(img/buttons.png);
	background-position: -70px 0;
}

.fn-gantt .navigate .nav-prev-week {
	background-image: url(img/buttons.png);
	background-position: -90px 0;
}

.fn-gantt .navigate .nav-prev-day {
	background-image: url(img/buttons.png);
	background-position: -110px 0;
}

.fn-gantt .navigate .nav-next-day {
	background-image: url(img/buttons.png);
	background-position: -130px 0;
}

.fn-gantt .navigate .nav-next-week {
	background-image: url(img/buttons.png);
	background-position: -150px 0;
}

.fn-gantt .navigate .nav-end {
	background-image: url(img/buttons.png);
	background-position: -170px 0;
}

.fn-gantt .navigate .nav-begin:hover {
	background-position: -70px -20px;
}

.fn-gantt .navigate .nav-prev-week:hover {
	background-position: -90px -20px;
}

.fn-gantt .navigate .nav-prev-day:hover {
	background-position: -110px -20px;
}

.fn-gantt .navigate .nav-next-day:hover {
	background-position: -130px -20px;
}

.fn-gantt .navigate .nav-next-week:hover {
	background-position: -150px -20px;
}

.fn-gantt .navigate .nav-end:hover {
	background-position: -170px -20px;
}
